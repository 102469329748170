import React, { useState, useEffect } from 'react';
import './css/Plotter.css';
import Slider from '@mui/material/Slider';
import Tex2SVG from "react-hook-mathjax";
import { LineChart } from '@mui/x-charts/LineChart';
import Box from '@mui/material/Box';




const Plotter = ({ data }) => {
    const [frequency, setFrequency] = useState(1);
    const [allAverage, setAllAvarage] = useState([]);


    useEffect(() => {

        if (!data) {
            return;
        }

        const newAllAverage = [];
        for (let i = 0; i <= 5; i += 0.01) {
            const allPoints = [];
            for (let k = 0; k < data.length; k++) {
                allPoints.push(getPoint(data[k].y, data[k].x, i));
            }
            const averagePoint = calculateAveragePoint(allPoints);
            let distance = Math.sqrt(averagePoint.x ** 2 + averagePoint.y ** 2);
            newAllAverage.push({ y: distance, x: i });
        }
        setAllAvarage(newAllAverage);
    }, [data, frequency])

    const drawGrid = () => {
        const lines = [];
        const rows = 10;
        const cols = 10;
        const rowSpacing = 100 / rows;
        const colSpacing = 100 / cols;
        for (let i = 0; i <= cols; i++) {
            const x = i * colSpacing;
            if (i == cols / 2) {
                lines.push(
                    <line key={`v-${i}`} x1={x} y1="0" x2={x} y2="100" style={{ stroke: 'black', strokeWidth: 0.3 }} />
                );
            }
            else {
                lines.push(
                    <line key={`v-${i}`} x1={x} y1="0" x2={x} y2="100" style={{ stroke: 'gray', strokeWidth: 0.2 }} />
                );
            }
        }



        for (let i = 0; i <= rows; i++) {
            const y = i * rowSpacing;

            if (i == rows / 2) {
                lines.push(
                    <line key={`h-${i}`} x1="0" y1={y} x2="100" y2={y} style={{ stroke: 'black', strokeWidth: 0.3 }} />
                );
            } else {
                lines.push(
                    <line key={`h-${i}`} x1="0" y1={y} x2="100" y2={y} style={{ stroke: 'gray', strokeWidth: 0.2 }} />
                );
            }

        }

        return lines;
    };

    const drawLine = (startPoint, endPoint) => {
        return (<line key={`h-${Math.random()}`} x1={startPoint.x * 50 + 50} y1={startPoint.y * 50 + 50} x2={endPoint.x * 50 + 50} y2={endPoint.y * 50 + 50} style={{ stroke: 'blue', strokeWidth: 0.5 }} />);
    };

    const drawLines = () => {
        const allPoints = [];
        const lines = [];

        const maxY = Math.max(...data.map(point => point.y));
        const scaleFactor = 1 / maxY;

        for (let i = 0; i < data.length - 1; i++) {
            let start = getPoint(data[i].y * scaleFactor, data[i].x, frequency);
            let end = getPoint(data[i + 1].y * scaleFactor, data[i + 1].x, frequency);
            lines.push(drawLine(start, end));
            allPoints.push(start);
        }

        const averagePoint = calculateAveragePoint(allPoints);

        // Draw the circle at the average point
        lines.push(
            <circle
                key="average-circle"
                cx={averagePoint.x * 50 + 50} // Adjusting for SVG coordinate space
                cy={averagePoint.y * 50 + 50} // Adjusting for SVG coordinate space
                r={2} // Radius of the circle
                fill="red" // Fill color of the circle
            />
        );

        return lines;
    };

    const getPoint = (amplitutde, rotation, frequency) => {
        let angleInDegrees = rotation * 360 / 5;
        angleInDegrees = angleInDegrees * frequency * 5;
        const angleInRadians = (angleInDegrees * Math.PI) / 180;

        const endX = amplitutde * Math.cos(angleInRadians);
        const endY = amplitutde * Math.sin(angleInRadians);

        return ({ x: endX, y: endY })
    };

    const calculateAveragePoint = (points) => {
        if (points.length === 0) return { x: 0, y: 0 };
        const sum = points.reduce(
            (acc, point) => {
                acc.x += point.x;
                acc.y += point.y;
                return acc;
            },
            { x: 0, y: 0 }
        );

        return {
            x: sum.x / points.length, // Average x
            y: sum.y / points.length  // Average y
        };
    };


    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth:'100vw'}}>
            <div style={{ flex: '1 1 0', margin: '20px', height:'100%',maxWidth:'100vw', width:'auto', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{ padding: '10px',  position:'absolute', background:'white', border:'solid', borderStyle:'groove'}}>
                    <Tex2SVG display="inline" latex={`g(t)e^{-2\\pi ift}`} />
                </div>
                <svg viewBox="0 0 100 100" preserveAspectRatio="none" style={{ height: '100%', maxHeight:'500px', width:'auto', }}>
                    {drawGrid()}
                    {drawLines()}
                </svg>
                <Box sx={{display: 'flex', alignItems: 'center', margin: '0', padding: '0', width:'100%' }}>
                    <div style={{ margin: '15px' }}>
                        <Tex2SVG display="inline" latex={`f=${Math.floor(frequency * 100)}\\`} />
                    </div>

                    <Slider
                        sx={{ marginTop: '10px' }}
                        size="small"
                        defaultValue={100}
                        step={2}
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        className="slider"
                        max={500}
                        onChange={(event, value) => setFrequency(value/100)}
                        //value={Math.floor(frequency * 100)}
                    />
                </Box>
            </div>
            <div style={{ flex: '1 1 0', margin: '20px', position: 'relative',maxWidth:'50vw',minWidth:'330px', minHeight:'250px'}}>


                <div style={{ position: 'absolute', right: '50px', background:'white', zIndex:1, padding: '10px', border:'solid', borderStyle:'groove' }}>
                    <Tex2SVG
                        display="inline"
                        latex={`\\frac{1}{N}  \\sum_{k=1}^{N}  g(t)e^{-2\\pi ift}\\`}
                    />
                </div>

                <LineChart
                    sx={{maxHeight:'550px', width:'100%', height:'100%'}}
                    margin={{ top: 10, bottom: 40, }}
                    grid={{ vertical: true, horizontal: false }}
                    xAxis={[
                        {
                            data: allAverage.map(point => point.x),
                            valueFormatter: (value) => (value == 0 ? '0' :  value % 1 === 0 ? String(value)+ '00' : ''), // Only show integers
                            label: 'Frequency'
                        },
                    ]}
                    yAxis={[
                        {
                            label: 'center of mass'
                        },
                    ]}
                    series={[
                        {
                            data: allAverage.map(point => point.y),
                            showMark: ({ index }) => false,
                        },
                    ]}
                />
            </div>
        </div>
    );
}

export default Plotter;