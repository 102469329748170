import React, { useState, useEffect } from "react";
import Layout from "../shared/Layout";
import Box from "@mui/material/Box";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid2 from "@mui/material/Grid2";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { useAuthApi } from '../Auth/AuthApi';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles({
  customScrollbar: {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
});

const UsersManagement = () => {
  const classes = useStyles();
  const { getUsers, getRoles, addUser, deleteUser, editUser } = useAuthApi();
  const [users, setUsers] = useState([]);
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
  const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
  const [newUsername, setNewUsername] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [addUserError, setAddUserError] = useState('');
  const [editUserError, setEditUserError] = useState('');
  const [userToEdit, setUserToEdit] = useState(null);
  const [mustChangePassword, setMustChangePassword] = useState(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const fetchData = async () => {
    let resp = await getUsers();
    if (resp.success) {
      setUsers(resp.users);
    }
    let respRoles = await getRoles();
    if (respRoles.success) {
      setRoles(respRoles.roles);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSetRoles = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRoles(typeof value === 'string' ? value.split(',') : value);
  };

  const handleOpenAddUserDialog = () => {
    setOpenAddUserDialog(true);
  };

  const handleCloseAddUserDialog = () => {
    setAddUserError("");
    setNewUsername("");
    setNewPassword("");
    setNewPassword2("");
    setSelectedRoles([]);
    setOpenAddUserDialog(false);
  };

  const handleAddUser = async () => {
    if (!newUsername || !newPassword || newPassword !== newPassword2) {
      return;
    }

    let resp = await addUser(newUsername, newPassword, selectedRoles);

    if (!resp.success) {
      setAddUserError(resp.error.map(x => x.code).join('\n'));
      return;
    }
    await fetchData();
    handleCloseAddUserDialog();
  };

  const handleOpenEditUserDialog = (user) => {
    setUserToEdit(user);
    setSelectedRoles(user.roles);
    setNewPassword(""); 
    setNewPassword2("");
    setMustChangePassword(user.changePassword || false); 
    setOpenEditUserDialog(true);
  };

  const handleCloseEditUserDialog = () => {
    setEditUserError("");
    setOpenEditUserDialog(false);
  };

  const handleEditUser = async () => {
    if (newPassword  !== newPassword2) {
      setEditUserError("Passwords dont match!");
      return; // Nothing to edit
    }
    let resp = await editUser(userToEdit.username, newPassword, selectedRoles, mustChangePassword);
    if (!resp.success) {
      setEditUserError(resp.error.errors.map(x => x.code).join('\n'));
      return;
    }
    await fetchData();
    handleCloseEditUserDialog();
  };

  const handleOpenConfirmDialog = (user) => {
    setUserToDelete(user);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setUserToDelete(null);
    setOpenConfirmDialog(false);
  };

  const handleDeleteUser = async (username) => {
    if (!username) {
      return;
    }

    await deleteUser(username);
    await fetchData();
    handleCloseConfirmDialog();
  };

  return (
    <Layout>
      <CssBaseline />
      <Grid2
        component={Paper}
        elevation={1}
        square
        style={{ width: "95vw", height: "85vh" }}
      >
        <Box
          sx={{
            my: 4,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: '100%',
            position: 'relative'
          }}
        >
          <Box
            className={classes.customScrollbar}
            sx={{
              width: "100%",
              maxHeight: "70vh",
              overflowY: "auto",
              mb: 2,
            }}
          >
            <List>
              {users.map((user, index) => (
                <ListItem key={index} divider>
                  <ListItemText primary={user.username} secondary={user.roles.join(', ')} />
                  <IconButton
                    style={{ marginRight: '1vw' }}
                    edge="end"
                    aria-label="edit-user"
                    onClick={() => handleOpenEditUserDialog(user)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    style={{ marginRight: '1vw' }}
                    edge="end"
                    aria-label="delete-user"
                    onClick={() => handleOpenConfirmDialog(user)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </Box>

          <Button
            style={{ position: 'absolute', bottom: '10%' }}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpenAddUserDialog}
          >
            Add User
          </Button>

          <Dialog open={openAddUserDialog} onClose={handleCloseAddUserDialog}>
            <DialogTitle>Add New User</DialogTitle>
            <DialogContent>
              <TextField
                margin="dense"
                label="Username"
                fullWidth
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
                required
                error={!newUsername.trim()}
                helperText={!newUsername.trim() ? "Username is required" : ""}
              />
              <TextField
                margin="dense"
                label="Password"
                type="password"
                fullWidth
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                error={!newPassword.trim()}
                helperText={!newPassword.trim() ? "Password is required" : ""}
              />
              <TextField
                margin="dense"
                label="Confirm Password"
                type="password"
                fullWidth
                value={newPassword2}
                onChange={(e) => setNewPassword2(e.target.value)}
                required
                error={!newPassword2.trim() || newPassword !== newPassword2}
                helperText={!newPassword2.trim() ? "Passwords don't match!" : ""}
              />
              <InputLabel id="demo-multiple-checkbox-label">Roles</InputLabel>
              <Select
                style={{ width: '100%' }}
                labelId="multiple-checkbox-label"
                id="multiple-checkbox"
                multiple
                value={selectedRoles}
                onChange={handleSetRoles}
                input={<OutlinedInput label="Roles" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {roles.map((role) => (
                  <MenuItem key={role} value={role}>
                    <Checkbox checked={selectedRoles.includes(role)} />
                    <ListItemText primary={role} />
                  </MenuItem>
                ))}
              </Select>
              {addUserError && (<Typography color="red">{addUserError}</Typography>)}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAddUserDialog} color="secondary">
                Cancel
              </Button>
              <Button onClick={handleAddUser} color="primary">
                Add User
              </Button>
            </DialogActions>
          </Dialog>

          {/* Edit User Dialog */}
          <Dialog open={openEditUserDialog} onClose={handleCloseEditUserDialog}>
            <DialogTitle>Edit User {userToEdit?.username }</DialogTitle>
            <DialogContent>
              {userToEdit && (
                <>
                  <TextField
                    margin="dense"
                    label="New Password"
                    type="password"
                    fullWidth
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    helperText="Leave blank if you don't want to change the password."
                  />
                  <TextField
                    margin="dense"
                    label="Confirm New Password"
                    type="password"
                    fullWidth
                    value={newPassword2}
                    onChange={(e) => setNewPassword2(e.target.value)}
                    helperText="Leave blank if you don't want to change the password."
                  />
                  <InputLabel id="edit-user-roles-label">Roles</InputLabel>
                  <Select
                    style={{ width: '100%' }}
                    labelId="edit-user-roles-label"
                    id="edit-user-roles"
                    multiple
                    value={selectedRoles}
                    onChange={handleSetRoles}
                    input={<OutlinedInput label="Roles" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {roles.map((role) => (
                      <MenuItem key={role} value={role}>
                        <Checkbox checked={selectedRoles.includes(role)} />
                        <ListItemText primary={role} />
                      </MenuItem>
                    ))}
                  </Select>
                  <div>
                    <Checkbox
                      checked={mustChangePassword}
                      onChange={(e) => setMustChangePassword(e.target.checked)}
                    />
                    <span style={{ marginLeft: '8px' }}>User must change password on next login</span>
                  </div>
                  {editUserError && (<Typography color="red">{editUserError}</Typography>)}
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseEditUserDialog} color="secondary">
                Cancel
              </Button>
              <Button onClick={handleEditUser} color="primary">
                Save Changes
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openConfirmDialog}
            onClose={handleCloseConfirmDialog}
          >
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
              <p>Are you sure you want to delete the user <strong>{userToDelete?.username}</strong>?</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseConfirmDialog} color="secondary">
                Cancel
              </Button>
              <Button onClick={() => handleDeleteUser(userToDelete.username)} color="primary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Grid2>
    </Layout>
  );
};

export default UsersManagement;
