import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuthApi } from './AuthApi';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';


const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const { sendLoginrequest, verifyToken } = useAuthApi();   
    const [user, setUser] = useState({ username: "", role: "", remember: false, ChangePassword: false });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const storedToken = sessionStorage.getItem("token") || Cookies.get('token');
        if (storedToken) {
            const decodedToken = jwtDecode(storedToken);
            const checkTokenValidity = async () => {
                try {
                    const resp = await verifyToken();
                    if (resp.success) {
                        const roleClaim = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
                        const userRoles = Array.isArray(roleClaim) ? roleClaim : roleClaim ? [roleClaim] : [];
                        
                        let user = 
                        {
                            
                            username: decodedToken.name,
                            role: userRoles,
                            remember: false,
                            ChangePassword : resp.user.changePassword
                        };
                        setUser(user);
                    } 
                    else if(!resp.success)
                    {
                        console.error("Invalid token!", resp);
                        logout();
                    }
                } catch (error) {
                    console.error("Invalid token:", error);
                }
                finally
                {
                    setLoading(false);
                }
            };

            checkTokenValidity();
        }
        else
        {
            setLoading(false);
        }
    },[]);


    const login = async (username, password, remember) => {

        try {
            let response = await sendLoginrequest(username, password);

            if (!response.success) {
                return response;
            }

            const userClaims = jwtDecode(response.token);

            const roleClaim = userClaims["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
            const userRoles = Array.isArray(roleClaim) ? roleClaim : roleClaim ? [roleClaim] : [];
            let newUser = { username: username, role: userRoles, remember: remember, ChangePassword : response.ChangePassword }
            setUser(newUser);

            if (!remember) {
                sessionStorage.setItem("token", response.token);
            }
            else {
                Cookies.set('token', response.token, { expires: 30 });
            }

            return { success: true, error: '' };

        }
        catch (err) {
            console.error(err);
            return { success: false, error: err.message };
        }
    };

    const logout = () => {
        setUser({ username: "", role: "", remember: false });
        sessionStorage.removeItem("token");
        Cookies.remove('token');
    };

    return <AuthContext.Provider value={{user, login, logout, loading}}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};