import React, {useEffect, useState} from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid2 from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import dickeItem from "../pictures/dicke.ico"
import { useAuth } from "../Auth/authprovider";
import { useNavigate } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';

const Login = (props) => {
  const [account, setAccount] = useState({ username: "", password: "", remember: false});
  const [disableButton, setDisableButton] = useState(false);
  const [wrongCreds, setWrongCreds] = useState("");
  const { login, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
      if(user.name)
      {
        navigate("/family/home");
      }
  },[user])

  const handelAccount = (property, event) => {
    const accountCopy = { ...account };
    accountCopy[property] = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    setAccount(accountCopy);
  };


  const handelLogin = async (event) =>{
    event.preventDefault(); 

    setDisableButton(true);

    const { username, password, remember } = account;

    let resp = await login(username, password, remember);
    
    if(resp.success)
    {
        navigate("/family/home");
    }
    else if(resp)
    {
        setWrongCreds(resp.error);
        setAccount({ username: "", password: "", remember: account.remember});
    }

    setDisableButton(false);
    
  };

  const backgroundImageUrl = `https://picsum.photos/${window.innerWidth}/${window.innerHeight}`;


  return (
    <Grid2
      container
      component="main"
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `url(${backgroundImageUrl})`, // Optional background image
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <CssBaseline />
      <Grid2 xs={12} sm={8} md={5} component={Paper} elevation={1} square style={{maxWidth:'90vw'}}>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <img src={dickeItem} alt="Custom Icon" style={{ width: '100%', height: '100%' }} />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form onSubmit={handelLogin}>
            <TextField
              onChange={(event) => handelAccount("username", event)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoFocus
              autoComplete="username"
              value={account.username || ''}
            />
            <TextField
              onChange={(event) => handelAccount("password", event)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={account.password || ''}
            />
            <FormControlLabel
              control={<Checkbox value="remember" id="remember" color="primary" checked={account.remember} onChange={(event) => handelAccount("remember", event)}/>}
              label="Remember me"
            />
            <LoadingButton 
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              loading={disableButton}
            >
              Sign In
            </LoadingButton >
            <Box mt={5}></Box>
          </form>
          {wrongCreds && (<Typography color="red">
            {wrongCreds}
          </Typography>)}
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default Login;
