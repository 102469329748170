import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { useAuth } from "./authprovider";
import CircularProgress from '@mui/material/CircularProgress';

const Authorization = ({ permissions }) => {
    const { user, loading } = useAuth();
    const location = useLocation();


    
    if (loading) {
        return( 
        <div style={{width:'100vw', height:'100vh', position:'relative', }}>
            <CircularProgress sx={{height:'50%', position:'absolute'}}/>
        </div>); // Show a loading indicator or spinner
    }

    
    if (user && user.username) {

        
        const isAllowed = permissions.some(permission => user.role.includes(permission)); 
        
        if(isAllowed)
        {
            return <Outlet />;
        }

        
        return <Navigate to="/family/login" state={{ path: location.pathname }} replace />;
    }
    
    return <Navigate to="/family/login" state={{ path: location.pathname }} replace />;
};

export default Authorization;