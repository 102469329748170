import React, { useState, useEffect, useRef } from "react";
import Layout from "../../shared/Layout";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  CssBaseline,
  Button,
  Checkbox,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";
import FolderIcon from '@mui/icons-material/FolderOpenOutlined';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFileOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useFileApi } from "./FileApi";
import { makeStyles } from "@mui/styles";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import TableFooter from '@mui/material/TableFooter';
import { styled } from '@mui/material/styles';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles({
  customScrollbar: {
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
});

const FileBrowser = () => {
  const classes = useStyles();
  const [currentPath, setCurrentPath] = useState("");
  const [files, setFiles] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]); // To track selected files and directories
  const [loading, setLoading] = useState(false);
  const { getDirectory, downloadFile, createFolder, deleteFile, uploadFiles, getFreeDiskSpace} = useFileApi();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 670);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [fileToDelete, setFileToDelete] = useState([]);
  const [freeDiskSpace, setFreeDiskSpace] = useState(null);
  const uploadInputRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 670);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => 
  {
    const getDiskSpace = async () =>
    {
      var res = await getFreeDiskSpace();
      if(res.success)
      {
        let text = res.freeSpace ? "Free Space: " + res.freeSpace + " GB" : '';
        setFreeDiskSpace(text);
      }
    }
    getDiskSpace();
    

  }, []);

  useEffect(() => {
    fetchItems(currentPath);
  }, [currentPath]);


  const fetchItems = async (path) => {
    setLoading(true);
    try {
      const response = await getDirectory(path);
      if (response.success) {
        setFiles(response.files || []);
      } else {
        console.error("Failed to fetch items");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  const handleFolderClick = async (folderName) => {
    setCurrentPath(currentPath ? `${currentPath}/${folderName}` : folderName);
  };

  const handleGoBack = () => {
    const parts = currentPath.split("/").filter(Boolean);
    setCurrentPath(parts.slice(0, -1).join("/"));
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedItems(files);
    } else {
      setSelectedItems([]);
    }
  };

  const handleCheckboxClick = (item) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(item)
        ? prevSelected.filter((i) => i !== item)
        : [...prevSelected, item]
    );
  };

  const handleRowClick = (item) => {
    if (item.isDirectory) {
      handleFolderClick(item.name);
    }
  };

  function formatFileSize(size) {
    if (size === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return (size / Math.pow(k, i)).toFixed(2) + ' ' + sizes[i];
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const isSelected = (item) => selectedItems.includes(item);

  const handleDownloadFile = (file) => {
    if (!file && selectedItems.length === 0) {
      return;
    }

    downloadFile(getFullFileNames(file));

  };

  const getFullFileNames = (file) => {
    if (selectedItems.length > 0) {
      return selectedItems.map(x => currentPath ? `${currentPath}/${x.name}` : x.name);
    }
    else {
      return file.map(x => currentPath ? `${currentPath}/${x.name}` : x.name);
    }
  };

  const handleCreateFolder = async () => {
    if (newFolderName.trim()) {
      try {
        await createFolder(currentPath ? `${currentPath}/${newFolderName}` : newFolderName);
        fetchItems(currentPath);
        handleCreateFileDialogClose();
      } catch (error) {
        console.error("Error creating folder:", error);
      }
    }
  };

  const handleCreateFileDialogClose = () => {
    setDialogOpen(false);
    setNewFolderName("");
  };

  const handleCloseConfirmDialog = () => {
    setSelectedItems([]);
    setOpenConfirmDialog(false);
  };

  const handleDelete = async () => {
    await deleteFile(getFullFileNames(fileToDelete));
    await fetchItems(currentPath);
    handleCloseConfirmDialog();
  };

  const handleUploadClick = () => {
    uploadInputRef.current.click();
  };
  
  const handleFileChange = async (event) => {
    const selectedFiles = event.target.files;
    const filesArray = Array.from(selectedFiles);
    if (filesArray.length > 0) {
      const result = await uploadFiles(filesArray, currentPath);
      await fetchItems(currentPath);
    }
  };

  const contextMenue = (file = null) => {
    return (<PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <IconButton
            style={{ marginRight: '10px' }}
            edge="end"
            aria-label="more"
            {...bindTrigger(popupState)}
          >
            <MoreHorizIcon />
          </IconButton>
          <Menu {...bindMenu(popupState)}>
            <MenuItem onClick={() => { file ? handleDownloadFile([file]) : handleDownloadFile(); popupState.close(); }}>
              <ListItemIcon>
                <DownloadIcon />
              </ListItemIcon>
              <ListItemText>Download</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => {setFileToDelete([file]); setOpenConfirmDialog(true); popupState.close(); }}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>);
  };

  return (
    <Layout additionalText={freeDiskSpace}>
      <CssBaseline />
      <div
        component={Paper}
        elevation={1}
        style={{ width: "95vw", height: "85vh" }}
      >

        <TableContainer
          className={classes.customScrollbar}
          component={Paper}
          style={{ height: '85vh', overflow: 'auto' }}
        >
          {loading ? (
            <p>Loading...</p>
          ) : (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={selectedItems.length > 0 && selectedItems.length < files.length}
                      checked={files.length > 0 && selectedItems.length === files.length}
                      onChange={handleSelectAllClick}
                      inputProps={{ "aria-label": "select all files and folders" }}
                    />
                  </TableCell>
                  <TableCell style={{ width: '5%' }}></TableCell>
                  <TableCell>Name</TableCell>
                  {!isMobile && (<>
                    <TableCell align="center">Last Modified</TableCell>
                    <TableCell>Size</TableCell>
                    <TableCell>Uploaded by</TableCell>
                  </>
                  )}
                  <TableCell align="right">
                    {contextMenue()}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody style={{ marginBottom: '200px' }}>
                {files.map((file) => (
                  <TableRow
                    key={file.name}
                    hover
                    role="checkbox"
                    aria-checked={isSelected(file)}
                    selected={isSelected(file)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isSelected(file)}
                        onChange={() => handleCheckboxClick(file)}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" onClick={() => handleRowClick(file)}>
                      {file.isDirectory ? <FolderIcon /> : <InsertDriveFileIcon />}
                    </TableCell>
                    <TableCell component="th" scope="row" onClick={() => handleRowClick(file)} style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '150px'
                    }}>
                      {file.name}
                    </TableCell>
                    {!isMobile && (<>
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        onClick={() => handleRowClick(file)}
                      >
                        {file.lastModified}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        onClick={() => handleRowClick(file)}
                      >
                        {file.isDirectory ? '-' : formatFileSize(file.size)}
                      </TableCell>
                      <TableCell component="th" scope="row" onClick={() => handleRowClick(file)}>
                        {file.uploadedBy}
                      </TableCell>
                    </>)}
                    <TableCell align="right">
                      {contextMenue(file)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter style={{ position: 'sticky' }}>
                <TableRow style={{ position: 'relative' }}>
                  <TableCell colSpan={60} style={{ padding: 0, textAlign: 'center', backgroundColor: 'white' }}>
                    {currentPath ? (<IconButton style={{ margin: '10px', position: 'absolute' }} onClick={handleGoBack}>
                      <ArrowBackIcon />
                    </IconButton>) : null}
                    <div style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                      <Button startIcon={<FileUploadIcon />} variant="contained" style={{ margin: '10px' }}  onClick={handleUploadClick}>
                        Upload
                        <VisuallyHiddenInput
                           ref={uploadInputRef}
                           type="file"
                           onChange={handleFileChange}
                           multiple
                        />
                      </Button>
                      <Button startIcon={<CreateNewFolderIcon />} variant="contained" style={{ margin: '10px' }} onClick={() => setDialogOpen(true)}>
                        Folder
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          )}
        </TableContainer>
        <Dialog open={dialogOpen} onClose={handleCreateFileDialogClose}>
          <DialogTitle>Create New Folder</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Folder Name"
              fullWidth
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCreateFileDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCreateFolder} color="primary" variant="contained">
              Create
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openConfirmDialog}
          onClose={handleCloseConfirmDialog}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            {selectedItems.length > 0 ? (
              <p>Are you sure you want to delete <strong>{selectedItems.length} {selectedItems.length > 1 ? 'files' : 'file'}</strong>?</p>
            ) : fileToDelete.length > 0 ? (
              <p>Are you sure you want to delete <strong>{fileToDelete[0].name}</strong>?</p>
            ) : (
              <p>No file selected for deletion.</p>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirmDialog} color="secondary">
              Cancel
            </Button>
            <Button onClick={() => handleDelete(fileToDelete)} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    </Layout>
  );
};

export default FileBrowser;
