import Cookies from 'js-cookie';


export const useAuthApi = () => {

    const apiUrl = process.env.REACT_APP_API_URL;

    const sendLoginrequest = async (username, password) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/auth/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ userName: username, password: password }),
            });

            if (response.status === 401) {
                return { success: false, error: "Flasches Passwort du Looser!" };
            }

            if (!response.ok) {
                return { success: false, error: "Shit, versuchs noch mal oder frag Paul, das sieht nicht gut aus!" };
            }

            const data = await response.json();
            return { success: true, token: data.token, ChangePassword: data.changePassword};
        } catch (error) {
            return { success: false, error: "Shit, frag Paul was das ist: " + error.message };
        }
    };

    const verifyToken = async () => {
        const storedToken = sessionStorage.getItem("token") || Cookies.get('token');
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/auth/verify", {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${storedToken}`
                }
            });

            if (!response.ok) {
                console.error("Token verification failed");
                return { success: false, error: "Token verification failed." };
            }
            const data = await response.json();
            return { success: true, message: "Token is valid.", user:  data};
        } catch (error) {
            console.error(error);
            return { success: false, error: "Error verifying token: " + error.message };
        }
    };

    const changePassword = async (username, currentPassword, newPassword) => {
        const storedToken = sessionStorage.getItem("token") || Cookies.get('token');
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/auth/changePassword", {
                method: "Post",
                headers: {
                    "Authorization": `Bearer ${storedToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ userName: username, currentPassword: currentPassword, newPassword: newPassword}),
            });

            if (!response.ok) {
                const data = await response.text();
                return { success: false, error: data};
            }
            return { success: true, message: "Password changed!" };
        } catch (error) {
            console.error(error);
            return { success: false, error: "Error " + error.message };
        }
    };


    const editUser = async (username, password, roles, changePassword) => {
        const storedToken = sessionStorage.getItem("token") || Cookies.get('token');
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/auth/editUser", {
                method: "Post",
                headers: {
                    "Authorization": `Bearer ${storedToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ userName: username, password: password, roles: roles, changePassword: changePassword}),
            });

            if (!response.ok) {
                const data = await response.json();
                return { success: false, error: data};
            }
            return { success: true, message: "Password changed!" };
        } catch (error) {
            console.error(error);
            return { success: false, error: "Error " + error.message };
        }
    };

    const getUsers = async () => {
        const storedToken = sessionStorage.getItem("token") || Cookies.get('token');
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/auth/users", {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${storedToken}`
                }
            });

            if (!response.ok) {
                return { success: false, error: response.statusText };
            }
            const data = await response.json();
            return { success: true, message: "Sucess", users:  data};
        } catch (error) {
            console.error(error);
            return { success: false, error: "Error getting Users: " + error.message };
        }
    };

    const addUser = async (username, password, roles) => {
        const storedToken = sessionStorage.getItem("token") || Cookies.get('token');
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/auth/addUser", {
                method: "Post",
                headers: {
                    "Authorization": `Bearer ${storedToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ username: username, password: password, roles : roles}),
            });

            if (!response.ok) {
                const data = await response.json();
                return { success: false, error: data};
            }
            return { success: true, message: "Added User!" };
        } catch (error) {
            console.error(error);
            return { success: false, error: "Error " + error.message };
        }
    };

    const getRoles = async () => {
        const storedToken = sessionStorage.getItem("token") || Cookies.get('token');
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/auth/roles", {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${storedToken}`
                }
            });

            if (!response.ok) {
                return { success: false, error: response.statusText };
            }
            const data = await response.json();
            return { success: true, message: "Sucess", roles:  data};
        } catch (error) {
            console.error(error);
            return { success: false, error: "Error getting Users: " + error.message };
        }
    };

    const deleteUser = async (username) => {
        const storedToken = sessionStorage.getItem("token") || Cookies.get('token');
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/auth/deleteUser", {
                method: "Post",
                headers: {
                    "Authorization": `Bearer ${storedToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({Username: username}),
            });

            if (!response.ok) {
                const data = await response.text();
                return { success: false, error: data};
            }
            return { success: true, message: "User deleted!" };
        } catch (error) {
            console.error(error);
            return { success: false, error: "Error " + error.message };
        }
    };

    return { sendLoginrequest, verifyToken, changePassword, getUsers, addUser, getRoles, deleteUser, editUser};
};
