import { createTheme, ThemeProvider } from '@mui/material/styles';

// Create a theme with overrides
const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        footer: {
          left: 0,
          bottom: 0,
          zIndex: 2,
          position: 'sticky',
        },
      },
    },
  },
});

export default theme;
