import React, { useState, useEffect } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import Tex2SVG from "react-hook-mathjax";


const Transfom = ({ waveData }) => {
    const [freq, setFreq] = useState([]);
    const threshold = 0.5;


    useEffect(() => {
        let amplitudes = [];
        let currentIndex = 20;
        for (let i = 1; i <= 25; i++) {
            let coeff = coefficients(i);
            let amp = amplitude(coeff);
            amplitudes.push({ x: currentIndex, y: (Math.abs(amp) < threshold ? 0 : amp) });
            currentIndex += 20;
        }
        setFreq(amplitudes);

    }, [waveData]);



    const coefficients = (k) => {
        let sumReal = 0;
        let sumImaginary = 0;

        //sum = waveData[i].y * Math.pow(Math.E, -(i * 2 * Math.PI * k * i)/waveData.lenth);

        for (let i = 0; i < waveData.length; i++) {
            const exponent = -((2 * Math.PI * k * i) / waveData.length);
            const realPart = waveData[i].y * Math.cos(exponent);
            const imaginaryPart = waveData[i].y * Math.sin(exponent);
            sumReal += realPart;
            sumImaginary += imaginaryPart;
        }

        return { real: sumReal, imaginary: sumImaginary, complexString: `${sumReal} + ${sumImaginary}i` };
    };


    const amplitude = (number) => {
        let mag = (Math.sqrt(number.real ** 2 + number.imaginary ** 2)) * 4 / waveData.length;

        return mag;
    };



    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', top:'10px' ,right: '50px', background: 'white', zIndex: 1, padding: '10px', border: 'solid', borderStyle: 'groove' }}>
                <Tex2SVG
                    display="inline"
                    latex={`X_k  = \\sum_{n=1}^{N-1} X_n \\cdot e^{-\\frac{i2\\pi kn}{N}}\\`}

                />
            </div>
            <LineChart
                margin={{ top: 10, bottom: 40, }}
                grid={{ vertical: true, horizontal: true }}
                xAxis={[
                    {
                        data: freq.map(point => point.x),
                        label: 'Frequency Hz'
                    },
                ]}
                yAxis={[
                    {
                        label: 'Amplitude'
                    },
                ]}
                series={[
                    {
                        data: freq.map(point => point.y),
                        showMark: ({ index }) => false,
                    },
                ]}
                height={200}
            />
        </div>
    );
};

export default Transfom;