import React, { useState, useEffect, useCallback } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { Card, CardContent, Typography } from '@mui/material';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import './css/Wavefunction.css'
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { startPlaying } from '../FourierTransformation/Sound';
import { debounce } from 'lodash';


const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};


const WaveFunction = ({ style, onDataUpdate, index, onDelete, onAudioUpdate }) => {
  const [data, setData] = useState([]);
  const [frequency, setFrequency] = useState(1);
  const [amplitude, setAmplitude] = useState(1);
  const [color] = useState(getRandomColor())
  const resolution = 100;
  const gridLength = 5;


  useEffect(() => {
    const newData = [];
    for (let i = 0; i < gridLength * resolution; i++) {
      newData.push({ x: i / resolution, y: getWaveData(i / resolution, frequency) });
    }
    setData(newData);
    onDataUpdate(newData);
  }, [frequency, amplitude]);

  function getWaveData(time, frequency) {
    return (Math.sin(2 * Math.PI * time * frequency + (Math.PI / 2)) + 1) / 2 * amplitude;
  }


  const debouncedUpdateFrequency = useCallback(debounce((freq, amp) => {
    onAudioUpdate({ frequency: freq, amplitude: amp });
  }, 300), []); // Adjust the delay (in milliseconds) as needed

  const debouncedUpdateAmplitude = useCallback(debounce((freq, amp) => {
    onAudioUpdate({ frequency: freq, amplitude: amp });
  }, 300), []); // Adjust the delay (in milliseconds) as needed

  const handleFrequencyChange = (event, value) => {
    setFrequency(value / 100);
    debouncedUpdateFrequency(value / 100, amplitude);
  };

  const handleAmplitudeChange = (event, value) => {
    setAmplitude(value);
    debouncedUpdateAmplitude(frequency, value);
  };

  return (
    <div className="center-flex" style={style}>
      <Card className="center-flex" style={style}>
        <CardContent className="card-content" style={{ position: 'relative', minHeight: '300px', minWidth: '100%' }}>
          {
            index > 0 && (<IconButton aria-label="delete" color="grey" style={{ position: 'absolute', top: 7, right: 20 }} onClick={() => onDelete()}>
              <DeleteIcon />
            </IconButton>)
          }
          <IconButton aria-label="add" color="grey" onClick={() => startPlaying([{frequency:frequency,amplitude:amplitude}])} style={{ position: 'absolute', top: 7, left: 20 }}>
            <VolumeUpIcon />
          </IconButton>

          <Typography className="typography">
            {(index + 1) + ". Frequency " + Math.floor(frequency * 100) + " Hz"}
          </Typography>
          <LineChart
            margin={{ top: 10, bottom: 30, }}
            grid={{ vertical: true, horizontal: true }}
            xAxis={[
              {
                data: data.map(point => point.x),
                valueFormatter: (value) => (value == 0 ? '0' :  value % 1 === 0 ? String(value)+0 +' ms': ''), // Only show integers
              },
            ]}
            series={[
              {
                data: data.map(point => point.y),
                showMark: ({ index }) => false,
                color: color
              },
            ]}
          />
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', alignItems: 'center' }}>
            <Typography
              sx={{ marginLeft: '20px', marginRight: '10px', lineHeight: 'normal', height: '50%' }}
              id="input-slider" gutterBottom>
              Frequency
            </Typography>

            <Slider
              sx={{ marginLeft: '0px', marginRight: '50px', alignContent: 'center' }}
              value={Math.floor(frequency * 100)}
              size="small"
              defaultValue={100}
              step={2}
              aria-label="Small"
              valueLabelDisplay="auto"
              className="slider"
              max={500}
              onChange={handleFrequencyChange}
            />

          </Box>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <Typography
              sx={{ marginLeft: '20px', marginRight: '10px', lineHeight: 'normal', height: '50%' }}
              id="input-slider" gutterBottom>
              Amplitude
            </Typography>

            <Slider
              sx={{ marginLeft: '0px', marginRight: '50px' }}
              value={amplitude}
              size="small"
              defaultValue={1}
              step={0.5}
              min={1}
              aria-label="Small"
              valueLabelDisplay="auto"
              className="slider"
              max={5}
              onChange={handleAmplitudeChange}
            />

          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default WaveFunction;