import Cookies from 'js-cookie';

export const useFileApi = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const getDirectory = async (path = '') => {
        const storedToken = sessionStorage.getItem('token') || Cookies.get('token');
        try {
            const response = await fetch(`${apiUrl}/api/file/GetDirectoryContents?path=${encodeURIComponent(path)}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${storedToken}`
                }
            });

            if (!response.ok) {
                return { success: false, error: response.statusText };
            }

            const data = await response.json();
            return { success: true, files: data};
        } catch (error) {
            return { success: false, error: `Error fetching directory: ${error.message}` };
        }
    };


    const getFreeDiskSpace = async () => {
        const storedToken = sessionStorage.getItem('token') || Cookies.get('token');
        try {
            const response = await fetch(`${apiUrl}/api/file/freeSpace`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${storedToken}`
                }
            });

            if (!response.ok) {
                return { success: false, error: response.statusText };
            }

            const data = await response.text();
            return { success: true, freeSpace: data};
        } catch (error) {
            return { success: false, error: `Error fetching directory: ${error.message}` };
        }
    };

    
    const downloadFile = async (files) => {
        const storedToken = sessionStorage.getItem('token') || Cookies.get('token');
        try {
            const response = await fetch(`${apiUrl}/api/file/download`, {
                method: "Post",
                headers: {
                    "Authorization": `Bearer ${storedToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ files: files}),
            });

            if (!response.ok) {
                return { success: false, error: response.statusText };
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = files.length > 1 ? 'download.zip' : files[0];
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            return { success: true, message: 'File downloaded!' };
        } catch (error) {
            return { success: false, error: `Error downloading file: ${error.message}` };
        }
    };


    const deleteFile = async (files) => {
        const storedToken = sessionStorage.getItem('token') || Cookies.get('token');
        try {
            const response = await fetch(`${apiUrl}/api/file/delete`, {
                method: "Post",
                headers: {
                    "Authorization": `Bearer ${storedToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ files: files}),
            });

            if (!response.ok) {
                return { success: false, error: response.statusText };
            }
            return { success: true, message: 'Files deleted!' };
        } catch (error) {
            return { success: false, error: `Error deleting files: ${error.message}` };
        }
    };


    const createFolder = async (folderName) => {
        const storedToken = sessionStorage.getItem('token') || Cookies.get('token');
        try {
            const response = await fetch(`${apiUrl}/api/file/createFolder?folderName=${encodeURI(folderName)}`, {
                method: "Post",
                headers: {
                    "Authorization": `Bearer ${storedToken}`,
                    "Content-Type": "application/json",
                }
            });

            if (!response.ok) {
                return { success: false, error: response.statusText };
            }
            return { success: true, message: 'Folder created!'};
        } catch (error) {
            return { success: false, error: `Error downloading file: ${error.message}` };
        }
    };

    const uploadFiles = async (files, targetPath) => {
        const storedToken = sessionStorage.getItem('token') || Cookies.get('token');
    
        const formData = new FormData();
        
        files.forEach(file => {
            formData.append("files", file);
        });

        formData.append("TargetPath", targetPath);
    
        try {
            const response = await fetch(`${apiUrl}/api/file/upload`, {
                method: "POST",
                body: formData,
                headers: {
                    "Authorization": `Bearer ${storedToken}`,
                }
            });
    
            if (!response.ok) {
                return { success: false, error: response.statusText };
            }
    
            const data = await response.json();
            return { success: true, message: data.message, uploadedFiles: data.files };
        } catch (error) {
            return { success: false, error: `Error uploading files: ${error.message}` };
        }
    };
    

    return { getDirectory, downloadFile, createFolder, deleteFile, uploadFiles, getFreeDiskSpace};
};
