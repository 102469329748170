import React, { useState, useEffect } from "react";
import Grid2 from "@mui/material/Grid2";
import { HomeBar } from "./AppBar";
import { useChangePassword } from "../shared/useChangePassword";
import ChangePassword from "./ChangePassword";

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const Layout = ({ children, additionalText}) => {
  const [backgroundImageUrl, setBackgroundImageUrl] = useState(
    `https://picsum.photos/${window.innerWidth}/${window.innerHeight}`
  );

  const { openChangePassword, closeChangePassword, shouldShowChangePassword } =
    useChangePassword();

  const preloadAndSetBackgroundImage = (newImageUrl) => {
    const img = new Image();
    img.src = newImageUrl;
    img.onload = () => {
      setBackgroundImageUrl(newImageUrl);
    };
  };

  const updateBackgroundImage = () => {
    const newImageUrl = `https://picsum.photos/${window.innerWidth}/${window.innerHeight}`;
    preloadAndSetBackgroundImage(newImageUrl);
  };

  const debouncedUpdateBackgroundImage = debounce(updateBackgroundImage, 300);

  useEffect(() => {
    window.addEventListener("resize", debouncedUpdateBackgroundImage);

    return () => {
      window.removeEventListener("resize", debouncedUpdateBackgroundImage);
    };
  }, [debouncedUpdateBackgroundImage]);

  return (
    <Grid2
      container
      component="main"
      sx={{
        height: "100vh",
        maxHeight: '100vh',
        overflow: 'hidden',
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <HomeBar handleOpenChangePsw={openChangePassword} additionalText={additionalText} />
      {shouldShowChangePassword ? (
        <ChangePassword handleClose={closeChangePassword} />
      ) : (
        children
      )}
    </Grid2>
  );
};

export default Layout;
