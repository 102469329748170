import React, { useEffect, useState } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import WaveFunction from './Wavefunction';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Transfom from './Transform';
import { Card, CardContent, Typography } from '@mui/material';
import Plotter from './Plotter';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { startPlaying } from './Sound';
import { Helmet } from 'react-helmet';


const FourierTransformation = () => {
    const [waveData, setWaveData] = useState([]);
    const [waveFunctions, setWaveFunctions] = useState([1]);
    const [allWaveData, setAllWaveData] = useState([[]]);
    const [audio, setAudio] = useState([{ frequency: 1, amplitude: 1 }]);


    const handleDataUpdate = (index, updatedData) => {

        const newAllData = [...allWaveData];
        newAllData[index] = updatedData;
        setAllWaveData(newAllData);
    };

    useEffect(() => {
        const maxLength = Math.max(...allWaveData.map(arr => arr.length));
        let newWaveData = Array.from({ length: maxLength }, (_, idx) => ({ x: idx, y: 0 }));


        for (let i = 0; i < allWaveData.length; i++) {
            for (let k = 0; k < allWaveData[i].length; k++) {
                newWaveData[k].y += allWaveData[i][k].y;
                newWaveData[k].x = allWaveData[i][k].x;
            }
        }
        newWaveData = newWaveData.map(point => ({
            x: point.x,
            y: point.y
        }));
        setWaveData(newWaveData);
    }, [allWaveData]);

    const onFreqencyUpdate = (index, freq) => {
        const newFreq = [...audio];
        newFreq[index] = freq;
        setAudio(newFreq);
    };

    const addWaveFunction = () => {
        const newId = Date.now(); // Use current timestamp as a unique ID
        setWaveFunctions([...waveFunctions, { id: newId, frequency: 1 }]);
        setAudio([...audio, { frequency: 1, amplitude: 1 }]);
    };

    const handleDelete = (id) => {
        const updatedWaveFunctions = waveFunctions.filter((wave) => wave.id !== id);
        const indexToDelete = waveFunctions.findIndex((wave) => wave.id === id);

        const updatedWaveData = allWaveData.filter((_, index) => index !== indexToDelete);
        const updatedAudio = audio.filter((_, index) => index !== indexToDelete);

        setWaveFunctions(updatedWaveFunctions);
        setAllWaveData(updatedWaveData);
        setAudio(updatedAudio);
    };

    return (
        <>
            <Helmet>
                <title>Fourier Transformation</title>
                <link rel="icon" href="/fourier.ico" />
            </Helmet>
            <div style={{ position: 'fixed', top: '0', width: '100%', zIndex: '100', backgroundColor: 'white' }}>
                <p style={{ marginBottom: '0px' }}>Discrete Fourier-Transformation</p>
                <Card style={{ width: '100%', height: '100%' }}>
                    <CardContent className="card-content" style={{ width: '100%', height: '100%', padding: '0px' }}>
                        <LineChart
                            margin={{ top: 10, bottom: 40, }}
                            grid={{ vertical: true, horizontal: false }}
                            xAxis={[
                                {
                                    data: waveData.map(point => point.x),
                                    valueFormatter: (value) => (value == 0 ? '0' : value % 1 === 0 ? String(value) + '0 ms' : ''), // Only show integers
                                    label: 'Time'
                                },
                            ]}
                            yAxis={[
                                {
                                    label: 'Intensity'
                                },
                            ]}
                            series={[
                                {
                                    data: waveData.map(point => point.y),
                                    showMark: ({ index }) => false,
                                    label: 'g(t)',
                                },
                            ]}
                            height={200}
                        />
                        <IconButton aria-label="add" color="grey" onClick={() => startPlaying(audio)} style={{ height: '100%' }}>
                            <VolumeUpIcon />
                        </IconButton>
                    </CardContent>
                </Card>
            </div>

            <div style={{ marginTop: '300px', maxWidth: '100%' }}>

                <Transfom
                    waveData={waveData}
                />


                <Plotter
                    data={waveData}
                    sampleFrequency={5}
                />

                <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'center' }}>

                    {waveFunctions.map((waveFunction, index) => (
                        <div
                            style={{

                                margin: '20px',
                                maxWidth: '100%',

                            }}
                            key={waveFunction.id}
                        >
                            <WaveFunction
                                key={waveFunction.id}
                                onDataUpdate={(value) => handleDataUpdate(index, value)}
                                index={index}
                                onDelete={() => handleDelete(waveFunction.id)}
                                onAudioUpdate={(freq) => { onFreqencyUpdate(index, freq) }}
                                style={{ minHeight: '200px', maxWidth: '100%', width: '500px', }}
                            />
                        </div>
                    ))}


                    <div className="center-flex" style={{ minWidth: '300px', minHeight: '300px' }}>
                        <Card style={{ width: '540px', height: '100%' }}>
                            <CardContent className="card-content" style={{ width: '100%', height: '100%' }}>
                                <IconButton aria-label="add" color="grey" onClick={addWaveFunction} style={{ height: '100%' }}>
                                    <AddIcon />
                                </IconButton>
                            </CardContent>
                        </Card>
                    </div>
                </div>

            </div>
        </>

    );
};

export default FourierTransformation;
